import { Button } from "./ui/Button"

export default function EmptyState() {
    return(
        <div className="flex flex-col border-2 h-dvh border-dashed rounded-lg">
            <div className="flex-grow"></div>
            <div className="my-auto justify-center items-center ">
                <p className="text-lg font-medium">Create your first video</p>
                <a href='/videos/new'><Button>Get started</Button></a>  
            </div>
            <div className="flex-grow"></div>
        </div>
    )
}