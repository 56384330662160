import React, { useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from './ui/Popover';

const ColorSelector = ({ updateColorSelection }) => {
  const [inputColor, setInputColor] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputColor) {
      updateColorSelection(inputColor);
      setInputColor('');
    }
  };

  return (
    <Popover>
      <PopoverTrigger>
        <div className="flex justify-center items-center h-10 w-10 rounded-full bg-white cursor-pointer">
          +
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <input
            type="text"
            value={inputColor}
            onChange={(e) => setInputColor(e.target.value)}
            placeholder="Enter color (e.g., #fff or red)"
            className="border p-2 rounded"
          />
        </form>
      </PopoverContent>
    </Popover>
  );
};

export default ColorSelector;