import { Button } from "./ui/Button"

export default function ErrorPage() {
  return (
    <div className='flex flex-col'>
        <div className='flex flex-col flex-grow justify-center items-center h-screen'>
            <h2 className='text-3xl font-semibold'>We couldn&apos;t find this page</h2>
            <br></br>
            <p className='text-lg'>Hmmm it looks like its nowhere to be found. Maybe it&apos;s run away?</p>
            <br></br>
            <a href='/dashboard'><Button>Return home</Button></a>  
        </div>
    </div>
  )
}