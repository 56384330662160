import React, { useState, useEffect } from 'react';
import { Button } from './ui/Button';
import NavBar from "./NavBar";
import EmptyState from './EmptyState';
import supabase from '../lib/supabaseClient';

export default function Dashboard() {
  const [videos, setVideos] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  let userId = null

  useEffect(() => {
    getVideos();
  }, []);

  async function getVideos() {
    if (userId === null) {
      const { data } = await supabase.auth.getUser()
      userId = data.user.id
    }

    try {
      const { data: videoData } = await supabase
      .from("videos")
      .select("videoId, videoTitle, frames!inner(videoId, frameSequence, backgroundColor, textColor, content)")
      .eq("userId", userId)
      .filter("frames.frameSequence", "eq", 100);

      setVideos(videoData);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }

  //if (posts === 'User not found') {redirect('/login')}

  return (
    <div className='flex flex-row h-screen'>
      <NavBar />
      <div className='flex flex-col justify-content align-items pl-12 pr-8 flex-grow overflow-scroll bg-white'>
        <div className='p-2'></div>
        <div className='flex flex-row items-center'>
            <h1 className='py-2 text-2xl text-center font-semibold text-gray-900'>Your Videos</h1>
              <div className='flex-grow'></div>
                <a href='/videos/new'><Button>Create</Button></a>  
        </div>
        <div className='px-2 py-1'></div>
        {videos.length === 0 && (!isloading) ? 
        <EmptyState /> : 
        <div className='grid grid-cols-3 gap-5'>
            {videos.map((video) => (
              <a href={`/videos/${video.videoId}`} key={video.videoId} 
                className='col-span-1 h-full max-w-sm flex flex-col'>
                <div className='min-h-48 w-full flex-grow flex justify-center items-center border border-1 rounded-lg shadow-sm transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg' 
                style={{
                  backgroundColor: video.frames[0].backgroundColor,
                  color: video.frames[0].textColor,
                  }}>
                      {video.frames[0].content}</div>
                <div className='px-2 py-2'>
                  <p className='font-medium text-left'>{video.videoTitle}</p>
                </div>
              </a>
            ))} 
        </div>}
        <div className='px-2 py-3'></div>
      </div>
    </div>
  );
}


/*
import React from 'react';

const Card = ({ experiment, onClick }) => {
  return (
    <div className="transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg max-w-sm rounded overflow-hidden shadow-lg bg-white h-full" onClick={onClick}>
      <div>
        <img 
        src={experiment['Listing Image'][0]['url']}
        alt="Funnel stage"
        className="pb-4"
        height={24} 
        />
          <div className='px-3'>
            <Tag text={experiment['Product Touchpoint']}/>
            <div className="text-xl mb-2 py-2">{experiment['Experiment Name']}</div>
          </div>
        </div>
    </div>
  );
};

*/