/*import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';*/
import supabase from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "./ui/Tooltip"
import { Arrow } from '@radix-ui/react-tooltip';

export default function NavBar() {
    const navigate = useNavigate()

    const SignOut = async (e) => {        
        e.preventDefault();
        const { error } = await supabase.auth.signOut()
        
        if (!error) {
            navigate("/login")
        } else {
            console.log(error)
        }
    }

    return(
        <nav className="flex flex-col bg-rose-600 w-20 h-screen grow-0 shrink-0">
            <div className='flex flex-col items-start'>
                {/*<p className='text-white font-lg font-medium pl-4 py-2'>Storycuts</p>*/}
                <div className='w-full gap-y-2'>
                    {/*
                    <div className='group/nav-item ml-3 pl-1 mr-6 py-1 my-1 rounded-lg hover:bg-rose-50 hover:text-gray-900'>
                        <a href="/" className="flex flex-row w-full">
                            <DashboardIcon className='text-white group-hover/nav-item:text-gray-900'/>
                            <p className="pl-4 text-left text-md text-white w-full group-hover/nav-item:text-gray-900">Dashboard</p>
                        </a>
                    </div>
                    <div className='group/nav-item ml-3 pl-1 mr-6 py-1 my-1 rounded-lg hover:bg-rose-50 hover:text-gray-900'>
                        <a href="/integrations" className="flex flex-row w-full">
                            <IntegrationInstructionsIcon className='text-white group-hover/nav-item:text-gray-900'/>
                            <p className="pl-4 text-left text-md text-white w-full group-hover/nav-item:text-gray-900">Integrations</p>
                        </a>
                    </div>
                    <div className='group/nav-item ml-3 pl-1 mr-6 py-1 my-1 rounded-lg hover:bg-rose-50 hover:text-gray-900'>
                        <a href="/team" className="flex flex-row w-full">
                            <GroupsOutlinedIcon className='text-white group-hover/nav-item:text-gray-900'/>
                            <p className="pl-4 text-left text-md text-white w-full group-hover/nav-item:text-gray-900">Team</p>
                        </a>
                    </div>
                    <div className='group/nav-item ml-3 pl-1 mr-6 py-1 my-1 rounded-lg hover:bg-rose-50 hover:text-gray-900'>
                        <a href="/payments" className="flex flex-row w-full">
                            <SettingsIcon className='text-white group-hover/nav-item:text-gray-900'/>
                            <p className="pl-4 text-left text-md text-white w-full group-hover/nav-item:text-gray-900">Payments</p>
                        </a>
                    </div>
                    */}
                </div>
            </div>
            <div className='flex flex-col flex-grow py-1 my-1 justify-end items-center'>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Logout className='text-white' onClick={SignOut} />
                    </TooltipTrigger>
                    <TooltipContent> 
                        Log out<Arrow/>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
                
            </div>
        </nav>
    )
}
