import React, { useRef, useState } from 'react';
import { PlayCircle, PauseCircle } from "@mui/icons-material"

const AudioPreviewButton = ({ src }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);

  const handleTogglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play().catch(e => {
          console.error("Error playing audio:", e);
          setError("Error playing audio. Please check the file path and format.");
        });
        setIsPlaying(true);
      }
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="flex items-center space-x-2">
      {isPlaying ? (
        <PauseCircle 
          className="text-black cursor-pointer w-8 h-8" 
          onClick={handleTogglePlay}
        />
      ) : (
        <PlayCircle 
          className="text-black cursor-pointer w-8 h-8" 
          onClick={handleTogglePlay}
        />
      )}
      <audio 
        ref={audioRef} 
        src={src} 
        onEnded={handleAudioEnded}
        onError={() => setError("Error loading audio. Please check the file path and format.")}
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default AudioPreviewButton;